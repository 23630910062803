import React from 'react'
import gsap from 'gsap'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Icon from '~components/Icon'
import cn from 'classnames'
import { hero, withBtns, hero_content, hero_pic, hero_btns } from './style.module.scss'

export const Hero = ({ title, descr, buttons, children }) => {
  const handleScroll = (e) => {
    e.preventDefault()
    gsap.to(window, { scrollTo: '#about', ease: 'power2' })
  }
  return (
    <Container as="section" className={cn(hero, { [withBtns]: buttons })}>
      <div className={hero_content}>
        <h1>{title}</h1>
        <p>{descr}</p>
      </div>
      {buttons && (
        <div className={hero_btns}>
          <Button variant="pink-blue" as={Link} to="/contact">
            Talk to Us
          </Button>
          <Button variant="link" as={Link} to="#about" onClick={(e) => handleScroll(e)}>
            Learn More
            <Icon name="arrow-down" size="24" />
          </Button>
        </div>
      )}
      <div className={hero_pic}>{children}</div>
    </Container>
  )
}
