const DATA = [
  {
    icon: 'time',
    content: 'More than 5 years in the blockchain niche',
  },
  {
    icon: 'rocket',
    content: '50+ implemented web & mobile projects',
  },
  {
    icon: 'puzzle',
    content: 'Synergy of marketers, designers & developers',
  },
  {
    content: '--',
  },
]

export default DATA
