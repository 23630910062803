import React from 'react'
import { Container } from 'react-bootstrap'
import { team, team_title, team_list, team_pic, team_name, team_position } from './style.module.scss'

export const Team = ({ title, list }) => {
  return (
    <Container as="section" className={team}>
      <h2 className={team_title}>{title}</h2>
      <ul className={team_list}>
        {list.map(({ name, position }, i) => (
          <li key={'team' + i}>
            <div className={team_pic}></div>
            <p className={team_name}>{name}</p>
            <p className={team_position}>{position}</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}
