import React from 'react'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import { about, withPartners, about_content, about_partners } from './style.module.scss'

export const AboutSection = ({ descr, partners }) => {
  return (
    <Container id="about" className={cn(about, { [withPartners]: partners })}>
      <div className={about_content}>
        {descr.map((item, i) => (
          <p key={'p' + i}>{item}</p>
        ))}
      </div>
      {partners && (
        <ul className={about_partners}>
          {partners.map((item) => (
            <li key={item.name}>
              <GatsbyImage image={item.childImageSharp.gatsbyImageData} alt={item.name} style={{ display: 'block' }} />
            </li>
          ))}
        </ul>
      )}
    </Container>
  )
}
