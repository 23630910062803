import React from 'react'
import { Container } from 'react-bootstrap'
import Icon from '~components/Icon'
import DATA from './constants'
import cn from 'classnames'
import { features, features_list, features_icon } from './style.module.scss'

export const Features = () => {
  return (
    <div className={features}>
      <Container as="ul" className={cn(features_list, 'noLines')}>
        {DATA.map(({ icon, content }, i) => (
          <li key={'features' + i}>
            <div className={features_icon}>{icon && <Icon name={icon} size={110} />}</div>
            <p>{content}</p>
          </li>
        ))}
      </Container>
    </div>
  )
}
