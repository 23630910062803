import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '~components/Layout'
import S from '~components/seo'
import { Hero } from '~components/Hero'
import { HeroPic } from './components/HeroPic'
import { AboutSection } from '~components/AboutSection'
import { Features } from '~components/Features'
import { Team } from './components/Team'
import { Cta } from '~components/Cta'

export const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutJson {
        hero {
          title
          descr
        }
        about {
          descr
          partners {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 100, placeholder: TRACED_SVG)
            }
            name
          }
        }
        team {
          title
          list {
            name
            position
          }
        }
      }
    }
  `)
  const { hero, about, team } = data.aboutJson
  return (
    <Layout>
      <S title="About" />
      <Hero {...hero}>
        <HeroPic />
      </Hero>
      <AboutSection {...about} />
      <Features />
      <Team {...team} />
      <Cta />
    </Layout>
  )
}
